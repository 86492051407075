h1, h2, h3, h4, h5, h6, p {
  font-family: 'Open Sans', sans-serif;

  color: #313131;
}

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {

}

 /* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {}

 /* mobile wide/smaller tablets
---------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {}

/* mobile narrow
  -------------------------------------------------------------------------- */
@media only screen and (max-width: 480px) {}
