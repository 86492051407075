/* ------------------------------------------------------------------ */
/* e. Projects Section
/* ------------------------------------------------------------------ */

#projects {
    background: #ebeeee;
    padding-top: 3em;
    padding-bottom: 4em;
    /* text-align:center; */
}

.projects_header {

    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;

    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 3em;
    color: #95A3A3;
}

.projects_container {
    /* background-color: burlywood; */
    
    position: relative;

    width:60%;
    
    /* center this container */
    margin: 0 auto;
    /* center the innner divs */
    text-align:center;
}

.projects_item {
    display: inline-block;
    /* background-color: aqua; */

    margin: 1em;
    /* margin: 0 auto; */
    /* text-align: center; */

    position: relative;

    width: 15em;
    /* height: 12em; */
}

.projects_item a {
    overflow: hidden;
    cursor: pointer;
}

.projects_item img {
    /* display:block; */
    /* width: 15em;
    height: 13em; */
    width: 100%;

    object-fit: fill;
}

/* Overlaying */
.projects_item__overlay___wrapper {
    /* display: flex;
    align-items: center; */
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
 
    opacity: 0;
    -moz-opacity: 0;
    filter:alpha(opacity=0);
 
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    background-color: rgba(255, 255, 255, 0.5);
    /* background: url(../../../public/images/overlay-bg.png) repeat; */
 }

.projects_item__link-icon {
    /* display: block; */
    color: #222;
    
    font-size: 1.2em;
    line-height: 30px;
    
    text-align: center !;
 
    opacity: 0;
    -moz-opacity: 0;
    filter:alpha(opacity=0);
 
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
 
    position: absolute;
    top: 80%;
    left: 45%;
    /* margin-left: -15px;
    margin-top: -15px; */
 }
 


.projects_item__overlay___wrapper h5 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    color: #222;

    margin: 0.3em;
    margin-top: 1.2em;
    /* text-align:left; */
    
}
.projects_item__overlay___wrapper p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #222;
}
.projects_item__overlay___wrapper .used-skills p {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: #c6c7c7;

    /* margin-top: 0.4em;
    margin-bottom: 0;
    margin-left: 0.2em;
    margin-right: 0.2em; */

    /* padding: 0.2em;

    text-align: left;
    line-height: 1em;

    display: inline-block;
    background-color: rgb(36, 31, 31); */

    display: inline-block;
    padding: 8px 5px;
    background-color: #313131;
    color: #c6c7c7;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: #222;
    border-radius: 3px;
    text-transform: uppercase;

    font-weight: 700;
    font-size: .75em;
    line-height: 1em;
    box-shadow: 1px 1px 0 rgb(0 0 0 / 25%);
    margin: 3px;
    opacity: 1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
 } 

 .used-skills {
    margin: 0.7em;
 }

 /* overlaying on hover */
.projects_item:hover .projects_item__overlay___wrapper{
	opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}
.projects_item:hover .projects_item__link-icon {
    opacity: 1;
    -moz-opacity: 1;
	filter:alpha(opacity=100);
}


/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
    .projects_item {
        width: 14em;
    }

    .projects_container {
        width:100%;
    }

    .projects_item__overlay___wrapper h5 {
        font-size: 1.1em;
    
        margin: 0.25em;
        margin-top: 1em;        
    }

    .projects_item__link-icon {
        position: absolute;
        top: 85%;
        left: 45%;
     }
     

}

 /* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
    .projects_item {
        width: 14em;
    }

    .projects_container {
        width:100%;
    }
}

 /* mobile wide/smaller tablets
---------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {}

/* mobile narrow
  -------------------------------------------------------------------------- */
@media only screen and (max-width: 480px) {
    .projects_item {
        width: 12em;
    }

    .projects_item__overlay___wrapper h5 {
        font-size: 1em;
    
        margin: 0.25em;
        margin-top: 1em;        
    }

     .projects_item__overlay___wrapper  p {
      display: none; 
    }
}