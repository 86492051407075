#about {
    background: #2B2B2B;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
}

/* general grid layout*/
.about_container {
   display: grid;
   height: 80vh;

   grid-template-columns: 20% 15% 20% 25% 20%;
   grid-template-rows: 50% 50%;
   /* background: white; */
   grid-gap: 0.2rem;
}

.about__avatar {
   grid-column: 2/3;
   grid-row: 1/2;
   /* background: #a7ffeb; */
}

.about__about-me {
   grid-column: 3/5;
   grid-row: 1/2;
   /* background: #84ffff; */
   padding-left: 1em;
   /* margin: 2em; */
}

.about__contact-me {
   grid-column: 3/4;
   grid-row: 2/3;
   /* background: #18ffff; */
   padding-left: 1em;
}

.about__download-resume {
   grid-column: 4/5;
   grid-row: 2/3;
}

/* avatar */
.about__avatar .about__avatar___img {
   position: relative;
   top: 10%;
   left: 10%;

   width: 7.5em;
   height: 7.5em;
   border-radius: 100%;
}

/* about-me */
.about__about-me .about__about-me___title {
   font-family: 'Open Sans', sans-serif;
   font-size: 25px;
   font-weight: 800;

   color: #fff;
   
   position: relative;
   top: 10%;

   margin-bottom: 0.19em;
   margin-top: 0.19em;
}

.about__about-me .about__about-me___p{
   font-family: 'Open Sans', sans-serif;
   font-weight: 500;
   font-size: 16px;

   color: #7A7A7A;

   position: relative;
   top: 20%;

   margin-bottom: 0.19em;
   margin-top: 0.19em;

   line-height: 2em;
}

/* contact-me */
.about__contact-me .about__contact-me___title {
   font-family: 'Open Sans', sans-serif;
   font-size: 25px;
   font-weight: 800;

   color: #fff;
   position: relative;


   position: relative;
   top: 10%;

   margin-bottom: 0.19em;
   margin-top: 0.19em;
}

.about__contact-me .about__contact-me___ul {
   font-family: 'Open Sans', sans-serif;
   font-weight: 500;
   font-size: 16px;

   line-height: 30px;
   color: #7A7A7A;

   list-style-type:none;
   list-style-position:inside;

   position: relative;
   top: 20%;

   margin-bottom: 0.19em;
   margin-top: 0.19em;
   
}

/* resume-download*/
.about__download-resume .about__download-resume___button{
   background-color: #7A7A7A;
   color: #ffffff;
   text-align: center;
   text-decoration: none;
   padding: 1em 1.5em;
   border-radius: 3px;
   position: relative;
   top: 30%;
   left: 10%;
}
.about__download-resume .about__download-resume___button i{
   font-size: 20px;
}


.about__download-resume .about__download-resume___button:hover {
   background: #fff;
   color: #2B2B2B;
}

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {

   /* general grid layout*/
   .about_container {
      grid-template-columns: 12.5% 20% 25% 30% 12.5%;
   }

   .about__about-me {
      padding-left: 0.7em;
      /* margin: 2em; */
   }
   
   .about__contact-me {
      padding-left: 0.7em;
   }

   /* avatar */
   .about__avatar .about__avatar___img {
      position: relative;
      top: 10%;
      left: 7%;
   
      width: 7em;
      height: 7em;
   }

   /* about-me */
   .about__about-me .about__about-me___title {
      font-size: 20px;
      
      position: relative;
      top: 10%;
   }
   
   .about__about-me .about__about-me___p{
      font-size: 16px;
   }

   /* contact-me */
   .about__contact-me .about__contact-me___title {
      font-size: 20px;
   }

   .about__contact-me .about__contact-me___ul {
      font-size: 16px;

      line-height: 30px;
      color: #7A7A7A;

      list-style-type:none;
      list-style-position:inside;

      position: relative;
      top: 20%;

      margin-bottom: 0.19em;
      margin-top: 0.19em;
      
   }

   /* resume-download*/
   .about__download-resume .about__download-resume___button i{
      font-size: 18px;
   }
 
}
 
 /* screenwidth less than 900px
 --------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
   
   /* general grid layout*/
   .about_container {
      display: grid;
      height: 80vh;

      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 40% 30% 20%;
      /* background: white; */
      grid-gap: 0.2rem;
   }

   .about__avatar {
      /* grid-column: 2/3;
      grid-row: 1/2;
      background: #a7ffeb; */
      display: none;
   }

   .about__about-me {
      grid-column: 2/3;
      grid-row: 1/2;
      /* background: #84ffff; */
      padding-left: 1em;
      /* margin: 2em; */
   }

   .about__contact-me {
      grid-column: 2/3;
      grid-row: 2/3;
      /* background: #18ffff; */
      padding-left: 1em;
   }

   .about__download-resume {
      grid-column: 2/3;
      grid-row: 3/4;
      /* text-align: center; */
   }

     /* about-me */
     .about__about-me .about__about-me___title {
      font-size: 20px;
      
      position: relative;
      top: 10%;
   }
   
   .about__about-me .about__about-me___p{
      font-size: 16px;
   }

   /* contact-me */
   .about__contact-me .about__contact-me___title {
      font-size: 20px;
   }

   .about__contact-me .about__contact-me___ul {
      font-size: 16px;

      line-height: 30px;
      color: #7A7A7A;

      list-style-type:none;
      list-style-position:inside;

      position: relative;
      top: 20%;

      margin-bottom: 0.19em;
      margin-top: 0.19em;
      
   }

   /* resume-download*/
   .about__download-resume .about__download-resume___button{
      position: relative;
      top: 40%;
      left: 35%;
   }
   .about__download-resume .about__download-resume___button i{
      font-size: 18px;
   }

}

 /* mobile wide/smaller tablets
---------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {
   /* general grid layout*/
   .about_container {
      display: grid;
      height: 80vh;

      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 40% 30% 20%;
      /* background: white; */
      grid-gap: 0.2rem;
   }

   .about__avatar {
      /* grid-column: 2/3;
      grid-row: 1/2;
      background: #a7ffeb; */
      display: none;
   }

   .about__about-me {
      grid-column: 2/3;
      grid-row: 1/2;
      /* background: #84ffff; */
      padding-left: 1em;
      /* margin: 2em; */
   }

   .about__contact-me {
      grid-column: 2/3;
      grid-row: 2/3;
      /* background: #18ffff; */
      padding-left: 1em;
   }

   .about__download-resume {
      grid-column: 2/3;
      grid-row: 3/4;
      /* text-align: center; */
   }

      /* about-me */
      .about__about-me .about__about-me___title {
      font-size: 20px;
      
      position: relative;
      top: 10%;
   }
   
   .about__about-me .about__about-me___p{
      font-size: 16px;
   }

   /* contact-me */
   .about__contact-me .about__contact-me___title {
      font-size: 20px;
   }

   .about__contact-me .about__contact-me___ul {
      font-size: 16px;

      line-height: 30px;
      color: #7A7A7A;

      list-style-type:none;
      list-style-position:inside;

      position: relative;
      top: 20%;

      margin-bottom: 0.19em;
      margin-top: 0.19em;
      
   }

   /* resume-download*/
   .about__download-resume .about__download-resume___button{
      position: relative;
      top: 40%;
      left: 15%;
   }
   .about__download-resume .about__download-resume___button i{
      font-size: 18px;
   }
}

/* mobile narrow
  -------------------------------------------------------------------------- */
@media only screen and (max-width: 480px) {
      /* general grid layout*/
      .about_container {
         display: grid;
         height: 100vh;
   
         grid-template-columns: 10% 80% 10%;
         grid-template-rows: 50% 25% 15%;
         /* background: white; */
         grid-gap: 0.2rem;
      }
   
      .about__avatar {
         /* grid-column: 2/3;
         grid-row: 1/2;
         background: #a7ffeb; */
         display: none;
      }
   
      .about__about-me {
         grid-column: 2/3;
         grid-row: 1/2;
         /* background: #84ffff; */
         padding-left: 1em;
         /* margin: 2em; */
      }
   
      .about__contact-me {
         grid-column: 2/3;
         grid-row: 2/3;
         /* background: #18ffff; */
         padding-left: 1em;
      }
   
      .about__download-resume {
         grid-column: 2/3;
         grid-row: 3/4;
         /* text-align: center; */
      }
   
        /* about-me */
        .about__about-me .about__about-me___title {
         font-size: 20px;
         
         position: relative;
         top: 5%;
         margin-top: 0.1em;
      }
      
      .about__about-me .about__about-me___p{
         font-size: 16px;
         margin-top: 0.1em;

         position: relative;
         top: 10%;
      }
   
      /* contact-me */
      .about__contact-me .about__contact-me___title {
         font-size: 20px;
         margin-top: 0.1em;

         position: relative;
         top: 5%;
      }
   
      .about__contact-me .about__contact-me___ul {
         font-size: 16px;
   
         line-height: 30px;
   
         position: relative;
         top: 10%;
   
         margin-top: 0.1em;
         
      }
   
      /* resume-download*/
      .about__download-resume .about__download-resume___button{
         position: relative;
         top: 40%;
         left: 4%;
      }
      .about__download-resume .about__download-resume___button i{
         font-size: 18px;
      }
}
