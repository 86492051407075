footer {

    background-color: #303030;
    font-size: 14px;

    text-align: center;

    position: relative;
    height: 13em;

    display: block;

 }
 

.footer__content {

   background-color: yellow;
   margin: 0;
   display: block;
   position: relative;

   text-align: center;
   /* width: 100%; */
 }

 footer p {
    color: white;

    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 15px;
    margin: 1em;
 }

 @media only screen and (max-width: 767px) {
    footer {
        height: 10em;
    }
 }