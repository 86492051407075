#home {
   /* position: relative; */
   height: 100vh;
   /* min-height: 20em; */
   width: 100%;
   /* height: 100%; */
   /* background-size: cover; */
   
   

   background: #161415 url(../../../public/images/home_bg.jpg) no-repeat top center;
   /* background-color: black; */
   background-size: cover !important;
    -webkit-background-size: cover !important;
   
   text-align: center;
   overflow: hidden;
}

/* vertically center banner section */
#home:before {
   content: '';
   display: inline-block;
   vertical-align: middle;
   height: 100%;
}


