/******************** 
  text banner section
*********************/

.banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 30px;
    text-align: center;
}
 
.banner-text { 
   width: 100%; 
}
.banner-text h1 {
   
   font-size: 90px;
   font-weight: 700;
   color: #fff;

   letter-spacing: -2px;
   margin: 0 auto 18px auto;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);

   margin-top: 1em;
   margin-bottom: 1em;
}

.banner-text h3 {
   font-size: 18px;
   
   color: #A8A8A8;
   margin: 0 auto;
   width: 70%;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
   
   line-height: 2.5em;
   
   margin-top: 1em;
   margin-bottom: 1em;
}

.banner-text h3 span, .banner-text h3 a {
   color: #fff;
}

/* .banner-text hr {
   width: 60%;
   margin: 18px auto 24px auto;
   border-color: rgba(150, 150, 150, .1);
} */

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {

  .banner-text h1 {
     font-size: 80px;
     font-weight: 700;
     letter-spacing: -1px;

     margin: 0 auto 0.6em auto;
  }

}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {

  .banner { 
     margin-top: 10%;
   }
  .banner-text h1 {
     /* font: 78px/1.1em 'opensans-bold', sans-serif; */
     font-size: 70px;
     font-weight: 700;
     letter-spacing: -1px;      
     /* padding-top: 5%; */
     margin-top: 0.3em;

  }

  .banner-text h3 {
     /* font: 17px/1.9em 'librebaskerville-regular', serif;
      */
     font-size: 17px;
     font-weight: 400;
     width: 80%;
  }

  .banner-text hr {
     width: 65%;
     margin: 0.75em auto;
  }
  /* nav-wrap */
  /* #nav-wrap {
     font: 11px 'opensans-bold', sans-serif;
     letter-spacing: 1.5px;
  } */
}