
/* general grid layout*/
.experience-theme_container {
    display: grid;
    grid-template-columns: 20% 20% 40% 20%;
    grid-gap: 0.2rem;
    
    padding-top: 4em;
    padding-bottom: 4em;
    margin-top: 3em;

    border-bottom: 1px solid #E8E8E8;
}

.experience-theme_container:last-child {
    margin-top: 0;
}

.experience-theme__header {
    grid-column: 2/3;
 }

.experience-theme__header___h1{

    font-family: 'Open Sans', sans-serif;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;

    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;   
    width: fit-content;

    /* position: relative;
    left: 20%;
    top: 10%; */
}

.experience-theme__entries {
    grid-column: 3/4; 
 }


.experience_theme__entries___row {  
    /* height: 15em; */
    margin-bottom: 1em;
}

.experience_theme__entries___row h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 800;

    /* position: relative;
    left: 3%;
    top: 15%;   */

    margin-bottom: 8px;
}

.info {
    font-size: 16px;
    font-style: italic; /**/

    color: #6E7881;
    margin-bottom: 18px;
    margin-top: 18px;

    /* position: relative;
    left: 3%;
    top: 10%;   */
}

.info span {
    margin-right: 5px;
    margin-left: 5px;
 }
.date {
    font-family: 'Open Sans', sans-serif;
    
    font-size: 15px;
    /* font-weight: 800; */

    margin-top: 6px;
 }

 .description {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    
    line-height: 1.5em;
    color: #767779;

    /* position: relative;
    left: 3%;
    top: 10%;   */
 }

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
    /* general grid layout*/
    .experience-theme_container {
        grid-template-columns: 15% 20% 50% 15%;
    }
}

 /* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
}

 /* mobile wide/smaller tablets
---------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
    /* flex */
    .experience-theme_container {
        display: flex;
        flex-direction: column;

        margin-left: 2em;
        margin-right: 2em;
    }

    .experience-theme__header___h1{
        margin-bottom: 1em;
    }


}

/* mobile narrow
  -------------------------------------------------------------------------- */
@media only screen and (max-width: 480px) {
    /* same as above */

}