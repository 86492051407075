 /***********************
 * social links 
 **********************/
 
.social {
    padding-top: 2em !important;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
 }
 .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
 }
 .social li a { color: #fff; }
 .social li a:hover { color: #11ABB0; }
 

 /* screenwidth less than 1024px
--------------------------------------------------------------------- */
 @media only screen and (max-width: 1024px) {
   .social {
      padding-top: 1.2em !important;
   }
 }
